import Header from "./Header";
import Landing from "./Landing";
import CokerTire from "./CokerTire";
import CorvetteCentral from "./CorvetteCentral";
import Zip from "./Zip";
import MOR from "./MOR";
import GreatRace from "./GreatRace";
import Contact from "./Contact";
import Footer from "./Footer";

const Main = () => {
  return (
    <>
      <Header />
      <Landing />
      <CokerTire />
      <CorvetteCentral />
      <Zip />
      <MOR />
      <GreatRace />
      <Contact />
      <Footer />
    </>
  );
};

export default Main;
