import cokerTireImg from "../img/coker-tire.jpg";
import cokerTireLogo from "../img/coker-tire-logo.png";

const CokerTire = () => {
  return (
    <div className="coker__container">
      <a className="anchor" id="cokertire" href="#cokertire">
        '
      </a>
      <img
        src={cokerTireImg}
        className="coker__img"
        alt="Coker Classic Star Series white wall tire"
      />
      <div className="coker__content--section">
        <div className="coker__content--box">
          <img
            src={cokerTireLogo}
            className="coker__content--logo"
            alt="Coker Tire Logo"
          />
          <p className="coker__content--text">
            Coker Tire is the most trusted source for specialty tires and
            wheels. With thousands of products to fit a 100-year range of
            vehicles, Coker Tire offers industry-leading service, innovative
            products and a commitment to quality control across all tire and
            wheel brands.
          </p>
          <a
            href="https://www.cokertire.com/about-us"
            target="_blank"
            className="coker__content--button"
            rel="noreferrer"
          >
            MORE INFO
          </a>
        </div>
      </div>
    </div>
  );
};

export default CokerTire;
