import React from "react";

const Footer = () => {
  return (
    <div className="footer__container">
      <p className="footer__text">&copy;2024 Coker Group. All rights reserved</p>
    </div>
  );
};

export default Footer;
