import greatRaceImg from "../img/great-race.jpg";
import greatRaceLogo from "../img/great-race-logo.png";

const GreatRace = () => {
  return (
    <div className="coker__container">
      <a className="anchor" id="greatrace" href="#greatrace">'</a>
      <img
        src={greatRaceImg}
        className="coker__img"
        alt="Vintage vehicle from the Great Race making it's way across the country."
      />
      <div className="coker__content--section">
        <div className="coker__content--box">
          <img
            src={greatRaceLogo}
            className="coker__content--logo-great-race"
            alt="Great Race Logo"
          />
          <p className="coker__content--text">
            The Great Race is the world's premier time, speed, endurance rally
            for classic cars. Each year, the event puts more than 120 classic
            cars against each other in a 2,300-mile rally on public roads across
            a nine-day span. The event features 18 hosted city stops each year.
          </p>
          <a
            href="https://www.greatrace.com/great-race-history"
            target="_blank"
            className="coker__content--button"
            rel="noreferrer"
          >
            MORE INFO
          </a>
        </div>
      </div>
    </div>
  );
};

export default GreatRace;
