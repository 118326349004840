import zipImg from "../img/zip.jpg";
import zipLogo from "../img/zip-logo.png";

const Zip = () => {
  return (
    <div className="coker__container">
      <a className="anchor" id="zip" href="#zip">'</a>
      <img src={zipImg} className="coker__img" alt="Modern Corvette" />
      <div className="coker__content--section">
        <div className="coker__content--box">
          <img
            src={zipLogo}
            className="coker__content--logo"
            alt="Zip Corvette Logo"
          />
          <p className="coker__content--text">
            Zip Corvette got its start in 1977 and quickly grew into a thriving
            business. Today, Zip's warehouse in Virginia includes more than
            25,000 of the newest available and best quality Corvette parts to be
            had.
          </p>
          <a
            href="https://www.zip-corvette.com/our-story.html"
            target="_blank"
            className="coker__content--button"
            rel="noreferrer"
          >
            MORE INFO
          </a>
        </div>
      </div>
    </div>
  );
};

export default Zip;
