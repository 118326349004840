import logo from "../img/logo.png";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  const listItemClicked = () => {
    document.getElementById("navi-toggle").checked = false;
  };

  return (
    <header className="header">
      <a href="/#home">
        <img src={logo} className="header__logo" alt="Coker Group logo" />
      </a>

      <div className="nav__collapsed">
        <input type="checkbox" className="nav__checkbox" id="navi-toggle" />
        <label htmlFor="navi-toggle" className="nav__button">
          <span className="nav__icon">&nbsp;</span>
        </label>
        <div className="nav__background">&nbsp;</div>

        <nav className="nav__nav">
          <ul className="nav__list--collapsed">
            <li className="nav__item--collapsed">
              <Link
                className="nav__link--collapsed"
                to="/#cokertire"
                onClick={() => listItemClicked()}
              >
                Coker Tire
              </Link>
            </li>
            <li className="nav__item--collapsed">
              <Link
                className="nav__link--collapsed"
                to="/#corvettecentral"
                onClick={() => listItemClicked()}
              >
                Corvette Central
              </Link>
            </li>
            <li className="nav__item--collapsed">
              <Link
                className="nav__link--collapsed"
                to="/#zip"
                onClick={() => listItemClicked()}
              >
                Zip Corvette
              </Link>
            </li>
            <li className="nav__item--collapsed">
              <Link
                className="nav__link--collapsed"
                to="/#mor"
                onClick={() => listItemClicked()}
              >
                MOR
              </Link>
            </li>
            <li className="nav__item--collapsed">
              <Link
                className="nav__link--collapsed"
                to="/#greatrace"
                onClick={() => listItemClicked()}
              >
                Great Race
              </Link>
            </li>
            <li className="nav__item--collapsed">
              <Link
                className="nav__link--collapsed"
                to="/news"
                onClick={() => listItemClicked()}
              >
                News
              </Link>
            </li>
            <li className="nav__item--collapsed">
              <a
                className="nav__link--collapsed"
                href="#careers"
                onClick={() => listItemClicked()}
              >
                Careers
              </a>
            </li>
            <li className="nav__item--collapsed">
              <a
                className="nav__link--collapsed"
                href="#contact"
                id="header__list-link--contact"
                onClick={() => listItemClicked()}
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <nav className="header__nav">
        <ul className="header__list">
          <div class="dropdown">
            <button class="dropbtn">
              OUR BRANDS
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content">
              <Link to="/#cokertire">COKER TIRE</Link>
              <Link to="/#corvettecentral">CORVETTE CENTRAL</Link>
              <Link to="/#zip">ZIP CORVETTE</Link>
              <Link to="/#mor">MOR</Link>
              <Link to="/#greatrace">GREAT RACE</Link>
            </div>
          </div>
          <li className="header__list-items">
            <Link className="header__list-link" to="/news">
              News
            </Link>
          </li>
          <li className="header__list-items">
            <a
              className="header__list-link"
              href="https://recruiting.paylocity.com/recruiting/jobs/All/593d40f5-a586-48b2-ac33-3b7212879b5f/Coker-Tire-Company"
              target="_blank"
              rel="noreferrer"
            >
              Careers
            </a>
          </li>
          <li className="header__list-items">
            <Link className="header__list-link" to="/#contact" href="#contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
