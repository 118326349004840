import Modal from "react-modal";
Modal.setAppElement("#root");

const NewsModal = ({ isModal, setIsModal, blog }) => {

  const customStyles = {
    overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
  };

  return (
    <>
      {/* Shows the modal a user would see logged in as admin - ADMIN only */}

      <Modal
        isOpen={isModal}
        onRequestClose={() => {
          setIsModal(false);
        }}
        ariaHideApp={false}
        className="news__blog"
        style={customStyles}
        contentLabel="New Blog Article"
      >
        <h1 className="news__blog--header">{blog.title}</h1>
            <h4 className="news__blog--sub">{blog.sub}</h4>
        <h2 className="news__blog--company">{blog.company}</h2>
        <h2 className="news__blog--date">{blog.date}</h2>
        <div
          className="news__blog--description"
          dangerouslySetInnerHTML={{
            __html: blog.description,
          }}
        ></div>
      </Modal>
    </>
  );
};

export default NewsModal;
