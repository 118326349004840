import cokerTireLogo from "../img/coker-tire-dark-logo.png";
import corvetteCentralLogo from "../img/corvette-central-dark-logo.png";
import mORLogo from "../img/mor-logo.png";
import zipLogo from "../img/zip-dark-logo.png";
import greatRaceLogo from "../img/great-race-logo.png";
import Map from "./Map";
import fb from "../img/social/facebook.png";
import yt from "../img/social/youtube.png";
import x from "../img/social/twitter.png";
import linkedIn from "../img/social/linkedin.png";
import instagram from "../img/social/instagram.png";

const Contact = () => {
  const locations = [
    {
      name: "COKER TIRE",
      lat: 35.04002,
      lng: -85.31306,
      logo: cokerTireLogo,
      street: "1317 Chestnut Street",
      city: "Chattanooga",
      state: "TN",
      zip: "37402",
      phone: "(423) 265-6368",
      fb: "https://www.facebook.com/cokertireco",
      yt: "https://www.youtube.com/user/cokertireco",
      insta: "https://www.instagram.com/cokertireco/",
      x: "https://twitter.com/cokertireco",
    },
    {
      name: "CORVETTE CENTRAL",
      lat: 41.87377,
      lng: -86.61144,
      logo: corvetteCentralLogo,
      street: "13550 Three Oaks Rd",
      city: "Sawyer",
      state: "MI",
      zip: "49125",
      phone: "(800) 345-4122",
      fb: "https://www.facebook.com/CorvetteCentral",
      yt: "https://www.youtube.com/user/CorvetteCentralMI",
      insta: "https://www.instagram.com/corvettecentral.original/?hl=en",
      x: "https://twitter.com/corvettecentral",
      link: "https://www.linkedin.com/company/corvette-central/",
    },
    {
      name: "ZIP",
      lat: 37.59995,
      lng: -77.37497,
      logo: zipLogo,
      street: "8067 Fast Ln",
      city: "Mechaniscsville",
      state: "VA",
      zip: "23111",
      phone: "(804) 746-2290",
      fb: "https://www.facebook.com/ZipCorvetteParts",
      yt: "https://www.youtube.com/user/ZipCorvette",
      insta: "https://www.instagram.com/corvetteparts/?hl=en",
      x: "https://twitter.com/Corvette_Parts",
    },
    {
      name: "MOR",
      lat: 35.04002,
      lng: -85.31306,
      logo: mORLogo,
      street: "Münchner Oldtimer Reifen GmbH, Gewerbering 14",
      city: "Holzkirchen",
      state: "Germany",
      zip: "83607",
      phone: "(800) 989-7223",
      fb: "https://www.facebook.com/MORGmbH",
    },
    {
      name: "GREAT RACE",
      lat: 35.04002,
      lng: -85.31306,
      logo: greatRaceLogo,
      street: "1317 Chestnut Street",
      city: "Chattanooga",
      state: "TN",
      zip: "37402",
      phone: "(800) 989-7223",
      fb: "https://www.facebook.com/greatracerally",
      yt: "https://www.youtube.com/greatracetv",
      insta: "https://www.instagram.com/greatracerally/",
      x: "https://twitter.com/thegreatrace",
    },
  ];

  const generateLocations = () => {
    return locations.map((location, i) => {
      return (
        <div key={i} className="contact__locations--box">
          <div>
            <img
              src={location.logo}
              className="contact__locations--logo"
              alt="Brand logo"
            />
          </div>
          <Map lat={location.lat} lng={location.lng} />
          <h2 className="contact__locations--title">{location.name}</h2>
          <p>{location.street}</p>
          <p>
            {location.city}, {location.state} {location.zip}
          </p>
          <p>{location.phone}</p>
          <div className="contact__social">
            {location.fb && (
              <a target="_blank" href={location.fb} rel="noreferrer">
                <img
                  src={fb}
                  className="contact__social--icon"
                  id="first-icon"
                  alt="Facebook Logo"
                />
              </a>
            )}
            {location.yt && (
              <a target="_blank" href={location.yt} rel="noreferrer">
                <img
                  src={yt}
                  className="contact__social--icon"
                  alt="YouTube Logo"
                />
              </a>
            )}
            {location.insta && (
              <a target="_blank" href={location.insta} rel="noreferrer">
                <img
                  src={instagram}
                  className="contact__social--icon"
                  alt="Instagram Logo"
                />
              </a>
            )}
            {location.x && (
              <a target="_blank" href={location.x} rel="noreferrer">
                <img src={x} className="contact__social--icon" alt="X Logo" />
              </a>
            )}
            {location.link && (
              <a target="_blank" href={location.link} rel="noreferrer">
                <img
                  src={linkedIn}
                  className="contact__social--icon"
                  alt="LinkedIn Logo"
                />
              </a>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <a className="anchor" id="contact" href="#contact">
        '
      </a>
      <h1 className="contact__heading">CONTACT US.</h1>
      <div className="contact__locations">{generateLocations()}</div>
    </div>
  );
};

export default Contact;
