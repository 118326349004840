// import GoogleMapReact from 'google-map-react';
import { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const Map = ({ lat, lng }) => {
  const [width, setWidth] = useState(1920);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle =
    width > 600
      ? {
          width: "250px",
          height: "250px",
        }
      : {
          width: "200px",
          height: "200px",
        };

  const center = {
    lat,
    lng,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDBiB7Ua7-B7kZX-HO-Z-33Y2K0pj_XTAw",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
    >
      <>
        <Marker position={{ lat, lng }} />
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
