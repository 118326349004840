import mORLogo from "../img/mor-logo.png";
import mORImg from "../img/mor.jpg";

const MOR = () => {
  return (
    <div className="coker__container">
      <a className="anchor" id="mor" href="#mor">
        '
      </a>
      <img
        src={mORImg}
        className="coker__img"
        alt="C8 Corvette with a number of other corvettes in a grassy field"
      />
      <div className="coker__content--section">
        <div className="coker__content--box">
          <img
            src={mORLogo}
            className="coker__content--logo"
            alt="Corvette Central Logo"
          />
          <p className="coker__content--text">
            Münchner Oldtimer Reifen GmbH (MOR) was founded in 1984 and serves
            the European market with high-quality tires, wheels and accessories
            for classic vehicles. MOR’s patented vulcanization process allows
            the company to add specialty sidewalls to any tire in its massive
            catalog.
          </p>
          <a
            href="https://oldtimerreifen24.de/about-us"
            target="_blank"
            className="coker__content--button"
            rel="noreferrer"
          >
            MORE INFO
          </a>
        </div>
      </div>
    </div>
  );
};

export default MOR;
