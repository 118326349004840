import landing from "../img/landing-background-2.jpg";
import mORLogo from "../img/mor-logo.png";
import cokerTireLogo from "../img/coker-tire-logo.png";
import corvetteCentralLogo from "../img/corvette-central-logo.png";
import zipLogo from "../img/zip-logo.png";
import greatRaceLogo from "../img/great-race-logo.png";

const Landing = () => {
  return (
    <div className="landing__container">
      <a className="anchor" id="home" href="#home">
        '
      </a>
      <img
        className="landing__img"
        src={landing}
        alt="Major Brand logos over a scenic road background"
      />
      <div className="landing__content">
        <h1 className="landing__header">
          BRANDS DRIVEN BY AUTOMOTIVE PASSION.
        </h1>
        <div className="landing__content--brands">
          <div className="landing__content--brand">
            <a href="#cokertire">
              <img
                className="landing__img--brand-ct"
                src={cokerTireLogo}
                alt="Coker Tire logo"
              />
            </a>
          </div>
          <div className="landing__content--brand">
            <a href="#corvettecentral">
              <img
                className="landing__img--brand-cc"
                src={corvetteCentralLogo}
                alt="Corvette Central logo"
              />
            </a>
          </div>
          <div className="landing__content--brand">
            <a href="#zip">
              <img
                className="landing__img--brand-zip"
                src={zipLogo}
                alt="Zip Corvette logo"
              />
            </a>
          </div>
          <div className="landing__content--brand">
            <a href="#mor">
              <img
                className="landing__img--brand-mor"
                src={mORLogo}
                alt="MOR logo"
              />
            </a>
          </div>
          <div className="landing__content--brand">
            <a href="#greatrace">
              <img
                className="landing__img--brand-gr"
                src={greatRaceLogo}
                alt="Great Race logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
