import corvetteCentralLogo from "../img/corvette-central-logo.png";
import corvetteCentralImg from "../img/corvette-central.jpg";

const CorvetteCentral = () => {
  return (
    <div className="coker__container">
      <a className="anchor" id="corvettecentral" href="#corvettecentral">
        '
      </a>
      <img
        src={corvetteCentralImg}
        className="coker__img"
        alt="C8 Corvette with a number of other corvettes in a grassy field"
      />
      <div className="coker__content--section">
        <div className="coker__content--box">
          <img
            src={corvetteCentralLogo}
            className="coker__content--logo"
            alt="Corvette Central Logo"
          />
          <p className="coker__content--text">
            Founded in 1975, Corvette Central is a leading provider of parts to
            upgrade, repair or restore all generations of the Chevrolet
            Corvette. The company has a reputation for providing a comprehensive
            portfolio of quality parts with the highest level of customer
            service.
          </p>
          <a
            href="https://www.corvettecentral.com/history"
            target="_blank"
            className="coker__content--button"
            rel="noreferrer"
          >
            MORE INFO
          </a>
        </div>
      </div>
    </div>
  );
};

export default CorvetteCentral;
